<template>
  <div class="p-relative" v-if="$auth.autenticado">
    <Header @toggleSider="setSider($event)" />

    <div class="container-fluid mt-4 tabs-container show-on-sm">
      <div class="tabs-wrapper show-on-sm flex-wrap justify-content-start">
        <div class="tab-button p-2 mb-2" @click="navigateTo('/oficinas')"
          :class="{ 'active-tab': currentUrl === '/oficinas' }" v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin'">
          Oficinas
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/usuarios')"
          :class="{ 'active-tab': currentUrl === '/usuarios' }"
          v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin'">
          Usuarios
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/clientes')"
          :class="{ 'active-tab': currentUrl === '/clientes' }" v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin'">
          Clientes
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/guias')"
          :class="{ 'active-tab': currentUrl === '/guias' }"
          v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin' || $auth.user.rol === 'operador'">
          Guias
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/manifiestos')"
          :class="{ 'active-tab': currentUrl === '/manifiestos' }"
          v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin' || $auth.user.rol === 'operador'">
          Manifiestos
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/cargas')"
          :class="{ 'active-tab': currentUrl === '/cargas' }"
          v-if="$auth.user.rol === 'superadmin'">
          Cargas
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4 d-sm-none">
      <div class="justify-content-end d-flex cursor-pointer mb-3" @click="toggleSider">
        <i :class="`${siderToggle ? 'fa-solid fa-xmark' : 'fa-solid fa-bars'}`"></i>
      </div>

      <div v-if="siderToggle" class="tabs-wrapper-small d-sm-none d-block mt-3">
        <div class="tab-button p-2 mb-2" @click="navigateTo('/oficinas')"
          :class="{ 'active-tab': currentUrl === '/oficinas' }" v-if="$auth.user.rol === 'superadmin'">
          Oficinas
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/usuarios')"
          :class="{ 'active-tab': currentUrl === '/usuarios' }"
          v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin'">
          Usuarios
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/clientes')"
          :class="{ 'active-tab': currentUrl === '/clientes' }" v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin'">
          Clientes
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/guias')"
          :class="{ 'active-tab': currentUrl === '/guias' }"
          v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin' || $auth.user.rol === 'operador'">
          Guias
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/manifiestos')"
          :class="{ 'active-tab': currentUrl === '/manifiestos' }"
          v-if="$auth.user.rol === 'superadmin' || $auth.user.rol === 'admin' || $auth.user.rol === 'operador'">
          Manifiestos
        </div>
        <div class="tab-button p-2 mb-2" @click="navigateTo('/cargas')"
          :class="{ 'active-tab': currentUrl === '/cargas' }"
          v-if="$auth.user.rol === 'superadmin'">
          Cargas
        </div>
      </div>

    </div>

    <div class="cursor-pointer d-flex align-items-center mb-3 mt-3" @click="navigateTo('/')" >
      <i class="fas fa-arrow-left"></i>
      <h6 class="ms-2">Home</h6>
    </div>

    <main class="mb-5">
      <slot />
    </main>
  </div>

  <div v-else class="d-flex align-items-center flex-column mt-4">
    <span class="spinner-border spinner-border-sm p-4 mb-3" role="status"></span>
    <h5 class="mb-0">Valiando Configuracion....</h5>
  </div>
</template>


<script setup>
import Header from "../components/dashboard/Header.vue";
import { useRoute, useRouter } from 'vue-router';
import { ref, watch } from 'vue';


const router = useRouter();
const route = useRoute();

const currentUrl = ref(route.fullPath);
const siderToggle = ref(false)


const navigateTo = (path) => {
  router.push(path);
  currentUrl.value = path;
};

const setSider = (e) => {
  siderToggle.value = e.value
}

const toggleSider = () => {
  siderToggle.value = !siderToggle.value
}

watch(
  () => route.fullPath,
  (newUrl) => {
    const url = new URL(newUrl, window.location.origin);
    url.searchParams.delete('type');
    currentUrl.value = url.pathname + url.search
  }
);

</script>




<style src="../assets/css/styles-sider.css"></style>
<style src="../assets/css/autenticado-layout.css"></style>

<style lang="scss">
.buttons-modal-footer {
  position: absolute;
  right: 0;
  bottom: 15px;
  width: 100%;

  button {
    margin: 5px;
    padding: 10px 20px;
    border-radius: 15px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s all;
  }

  .btn-modal-crear,
  .btn-modal-edit {
    background-color: var(--primary-2);
    color: #fff;

    &:hover {
      background-color: var(--primary-alt);
    }
  }

  .btn-modal-close {
    background-color: crimson;
    color: #fff;
  }
}

@media (max-width: 575.98px) {
  .show-on-sm {
    display: none;
  }
}

@media (min-width: 576px) {
  .show-on-sm {
    display: flex;
    /* O el tipo de visualización que necesites */
  }
}
</style>