<template>
  <header class="header">
    <div class="header__container">
      <div class="dropdown header__img d-flex align-items-center">
        <a
          class="
            btn btn-secondary
            dropdown-toggle
            avatar-profile
            text-uppercase
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ $auth.user.avatar }}
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li>
            <router-link class="dropdown-item text-dark" to="/perfil">
              <i class="fa-solid fa-user-gear fs-xl-2"></i>
              <span class="ms-2">Mi Perfil</span>
            </router-link>
          </li>

          <li>
            <a class="dropdown-item text-dark" @click="logout()">
              <i class="fa-solid fa-right-from-bracket fs-xl-2"></i>
              <span class="ms-2">Cerrar Sesión</span>
            </a>
          </li>
        </ul>
      </div>

      <a @click="$router.push('/')" class="header__logo text-uppercase c-pointer">Sur America Cargo</a>

    

      <!-- <div class="header__toggle" @click="toggleSider">
        <i :class="`${siderToggle ? 'fa-solid fa-xmark' : 'fa-solid fa-bars'}`"></i>
      </div> -->

      
    </div>
  </header>
</template>

<script setup>
import { useAuthStore } from "@/store/auth"
import {ref, defineEmits} from "vue"
import {useRouter} from 'vue-router'
const router = useRouter()
const siderToggle = ref(false)
const emit = defineEmits(['toggleSider'])

// const toggleSider = () => {
//   // siderToggle.value = !siderToggle.value
//   // emit('toggleSider', siderToggle)
// }

const logout = async () => {
  await useAuthStore().logout()
  router.push("/login")
}
</script>

